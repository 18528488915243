@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-loading-skeleton/dist/skeleton.css";
@import "react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css?family=Fraunces:300,300italic,400,400italic,500,600&display=swap");
@import url('https://fonts.googleapis.com/css?family=DM+Sans:300,400,500,600&display=swap');


@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@layer components {
  body {
    @apply text-content-regular;
  }
  th, td {
    @apply py-2 px-4;
  }
  h1, h2, h3 {
    @apply font-heading font-normal;
  }
  h4, h5 {
    @apply font-content font-semibold text-xl;
  } 
  p, li, th, td, .content {
    @apply font-content text-base;
  }
  .p-heading {
    @apply font-bold pt-4;
  }
  .app-container {
    @apply mx-auto overflow-hidden xl:container px-4 sm:px-8 md:px-12 lg:px-16;
  }
  .section-container {
    @apply py-8 space-y-6 px-4 sm:px-8 md:px-12 lg:px-16;
  }
  .section-container-x-padding-inverse {
    @apply -mx-4 sm:-mx-8 md:-mx-12 lg:-mx-16;
  }
  .btn-primary {
    @apply px-6 py-4 font-content font-semibold text-base text-brand-primary-regular bg-brand-tertiary-darker rounded-xl hover:shadow-lg disabled:bg-content-lighter;
  }
  .btn-secondary {
    @apply px-6 py-4 font-content font-semibold text-base text-brand-primary-regular bg-brand-tertiary-regular rounded-xl hover:shadow-lg disabled:bg-content-lighter;
  }
  .btn-negative {
    @apply px-6 py-4 font-content font-semibold text-base text-content-white bg-content-error rounded-xl hover:shadow-lg disabled:bg-content-lighter;
  }
  .btn-outline {
    @apply px-6 py-4 font-content font-semibold text-base text-brand-tertiary-darker border border-brand-tertiary-darker rounded-xl hover:shadow-lg disabled:bg-content-lighter disabled:border-content-lighter;
  }
  .btn-link {
    @apply p-2 -mx-2 underline underline-offset-2 font-content font-bold text-base;
  }
  .btn-text {
    @apply p-2 -mx-2 font-content font-medium text-base text-left;
  }
  .btn-inline {
    @apply underline underline-offset-2 font-semibold cursor-pointer;
  }
  .btn-style-thin {
    @apply py-1 px-2;
  }
  .btn-outline-rounded {
    @apply px-4 py-2 font-content font-semibold text-base text-brand-tertiary-darker border border-content-lighter rounded-3xl hover:shadow-lg disabled:bg-content-lighter disabled:border-content-lighter;
  }
  .btn-toggle {
    @apply px-4 py-2 font-content text-base text-brand-tertiary-darker border border-content-lighter rounded-3xl hover:shadow-lg disabled:font-semibold disabled:bg-content-regular disabled:border-content-regular disabled:text-brand-primary-regular;
  }
  .flex {
    @apply m-0
  }
  .rws-header {
    @apply font-heading font-medium text-xs !important;
  }
  .dark-mode > * {
    @apply text-content-regularOnDark !important;
  }
}

body {
  margin: 0;
  font-feature-settings: 'ss02' on, 'ss03' on;
}

input:-webkit-autofill + label,
input:-webkit-autofill:hover + label, 
input:-webkit-autofill:focus + label {
  transform: translate(20px, 12px) scale(0.75);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #1D0A3D;
  transition: transparent 5000s ease-in-out 0s;
}

.rws-container {
  width: 100% !important;
  overflow: hidden;
}

.rws-header {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'DM Sans';
}

.rws-close {
  background-color: #FFFCF3 !important;
  color: #1D0A3D;
  font-family: 'DM Sans';
  border-top: 1px solid #E2E2E2 !important;
}

.image-gallery-content.fullscreen {
  background: transparent !important;
}

.image-gallery.fullscreen-modal {
  background: #000000cc !important;
  backdrop-filter: blur(8px);
}

@media screen and (min-width: 769px) {
  .rws-backdrop {
    justify-content: center !important;
  }
  .rws-container {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
}